import React from 'react';
import PropTypes from 'prop-types';

import DetailSocialInteractiveRaw from './DetailSocialInteractiveRaw';

const DetailSocialInteractive = ({ data, actions }) => {
  if (
    data &&
    (data.tw ||
      data.fb ||
      data.ig ||
      data.ln ||
      data.yt ||
      data.vimeo ||
      data.pinterest ||
      data.pt ||
      data.tk)
  ) {
    return (
      <div className="free-row">
        <div className="social-interactive-icons-container">
          <DetailSocialInteractiveRaw data={data} actions={actions} />
        </div>
      </div>
    );
  }

  return null;
};

DetailSocialInteractive.propTypes = {
  data: PropTypes.object,
  actions: PropTypes.object.isRequired,
};
export default DetailSocialInteractive;

import React from 'react';
import PropTypes from 'prop-types';

import GenericIcon from 'src/components/generic-icon/GenericIcon';
import { isAndroid } from 'src/core/util/browser';

import Url from 'src/components/url/Url';

import 'app-customs/config-scss/GenericIcon.scss';

function DetailSocialInteractiveRaw({ data, actions }) {
  if (!data || typeof data !== 'object') {
    return null;
  }

  const target = global.isCordovaContext && !isAndroid() ? '_system' : null;
  const style = { WebkitMaskSize: '100%', maskSise: '100%', margin: 30 };

  return (
    <>
      {data.tw && (
        <Url
          className={data.tw.icon ? '' : 'twitter-icon'}
          callback={actions.linkClicked}
          href={data.tw.url}
          target={target}
          style={style}
        >
          {data.tw.icon && (
            <GenericIcon
              src={data.tw.icon}
              className="comments-icon fiche-icon"
              style={{ fontSize: '1.4em', height: '1.4em', width: '1.4em' }}
            />
          )}
        </Url>
      )}
      {data.fb && (
        <Url
          className={data.fb.icon ? '' : 'facebook-icon'}
          callback={actions.linkClicked}
          href={data.fb.url}
          target={target}
          style={style}
        >
          {data.fb.icon && (
            <GenericIcon
              src={data.fb.icon}
              className="comments-icon fiche-icon"
              style={{ fontSize: '1.4em', height: '1.4em', width: '1.4em' }}
            />
          )}
        </Url>
      )}
      {data.ig && (
        <Url
          className={data.ig.icon ? '' : 'instagram-icon'}
          callback={actions.linkClicked}
          href={data.ig.url}
          target={target}
          style={style}
        >
          {data.ig.icon && (
            <GenericIcon
              src={data.ig.icon}
              className="comments-icon fiche-icon"
              style={{ fontSize: '1.4em', height: '1.4em', width: '1.4em' }}
            />
          )}
        </Url>
      )}
      {data.ln && (
        <Url
          className={data.ln.icon ? '' : 'linkedin-icon'}
          callback={actions.linkClicked}
          href={data.ln.url}
          target={target}
          style={style}
        >
          {data.ln.icon && (
            <GenericIcon
              src={data.ln.icon}
              className="comments-icon fiche-icon"
              style={{ fontSize: '1.4em', height: '1.4em', width: '1.4em' }}
            />
          )}
        </Url>
      )}
      {data.yt && (
        <Url
          className={data.yt.icon ? '' : 'youtube-icon'}
          callback={actions.linkClicked}
          href={data.yt.url}
          target={target}
          style={style}
        >
          {data.yt.icon && (
            <GenericIcon
              src={data.yt.icon}
              className="comments-icon fiche-icon"
              style={{ fontSize: '1.4em', height: '1.4em', width: '1.4em' }}
            />
          )}
        </Url>
      )}
      {data.vimeo && (
        <Url
          className={data.vimeo.icon ? '' : 'vimeo-icon'}
          callback={actions.linkClicked}
          href={data.vimeo.url}
          target={target}
          style={style}
        >
          {data.vimeo.icon && (
            <GenericIcon
              src={data.vimeo.icon}
              className="comments-icon fiche-icon"
              style={{ fontSize: '1.4em', height: '1.4em', width: '1.4em' }}
            />
          )}
        </Url>
      )}
      {(data.pt || data.pinterest) && (
        <Url
          className={data.pt.icon || data.pinterest.icon ? '' : 'pinterest-icon'}
          callback={actions.linkClicked}
          href={data.pt.url || data.pinterest.url}
          target={target}
          style={style}
        >
          {(data.pt.icon || data.pinterest.icon) && (
            <GenericIcon
              src={data.pt.icon || data.pinterest.icon}
              className="comments-icon fiche-icon"
              style={{ fontSize: '1.4em', height: '1.4em', width: '1.4em' }}
            />
          )}
        </Url>
      )}
    </>
  );
}

DetailSocialInteractiveRaw.propTypes = {
  data: PropTypes.object,
  actions: PropTypes.object.isRequired,
};

export default DetailSocialInteractiveRaw;
